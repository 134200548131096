import './App.css';
import Formations from './Containers/Formations/Formations';

function App() {
  return (
    <div className="App">
      <Formations />
    </div>
  );
}

export default App;
